import {
  DeleteById,
  GetWithToken,
  PatchWithToken,
  PostWithToken,
  Get,
} from "../../lib/request";

// export const getAllOrderList = async() => {
//     const result = await GetWithToken('/order')
//     console.log(result,'sss');
//     return result;

// }
export const getAllOrderList = (query) => {
  // console.log(query);
  return async function (dispatch) {
    // console.log("enter");
    const result = await GetWithToken(
      `/v2/admin/order?item=${query.item || ""}&order=${query.order || ""}&orderId=${
        query.orderId || ""
      }&buyerName=${query.buyerName || ""}&merchantName=${
        query.merchantName || ""
      }&productName=${query.productName || ""}&status=${
        query.status || ""
      }&offset=${query.offset || 0}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&limit=${query.limit || 10}`
    );
    // const result = await GetWithToken(`/order?item=${query.item || ''}&order=${query.order || ''}&orderId=${query.orderId|| ''}&buyerName=${query.buyerName|| ''}&merchantName=${query.merchantName|| ''}&productName=${query.productName|| ''}&status=${query.status|| ''}&offset=${query.offset|| 0}`)

    console.log(result, "result for all consumer ");
    return result;
  };
};

export const getOrderByStoreId = (id, query) => {
  console.log(id);
  return async function (dispatch) {
    console.log("enter", query);
    const result = await GetWithToken(
      `/order/storeid/${id}?item=${query?.item || ""}&order=${
        query?.order || ""
      }&orderId=${query?.orderId || ""}&buyerName=${
        query?.buyerName || ""
      }&merchantName=${query?.merchantName || ""}&productName=${
        query?.productName || ""
      }&status=${query?.status || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&offset=${query?.offset || 0}&limit=${
        10 || 0
      }`
    );
    console.log(result, "result for all consumer ");
    return result;
  };
};

export const getOrderById = (id, query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/v2/admin/order/${id}?productId=${query.productId || ""}`
    );
    return result;
  };
};

export const deleteOrderById = (id) => {
  return async function (dispatch) {
    console.log(id, "enter");
    const result = await DeleteById(`/order/${id}`);
    console.log(result, "result for all consumer ");
    return result;
  };
};

export const getAllTeamPurchease = (query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/team-purchase?item=${query.item || ""}&order=${
        query.order || ""
      }&orderId=${query.orderId || ""}&buyerName=${
        query.buyerName || ""
      }&merchantName=${query.merchantName || ""}&productName=${
        query.productName || ""
      }&teamStatus=${query.teamStatus || ""}&offset=${
        query.offset || 0
      }&startDate=${query.startDate || ""}&endDate=${
        query.endDate || ""
      }&limit=${query.limit || 10}`
    );
    console.log(result, "result for all team");
    return result;
  };
};

export const getTeamPurcheaseByStoreId = (id, query) => {
  return async function (dispatch) {
    const result = await GetWithToken(
      `/team-purchase/storeid/${id}?item=${query.item || ""}&order=${
        query.order || ""
      }&orderId=${query.orderId || ""}&buyerName=${
        query.buyerName || ""
      }&merchantName=${query.merchantName || ""}&productName=${
        query.productName || ""
      }&teamStatus=${query.teamStatus || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&limit=${query.limit || 10}`
    );

    return result;
  };
};

export const postRedemption = (orderId, redemptionCode) => {
  return async function (dispatch) {
    const result = await PostWithToken(
      `/order/redemption?orderId=${orderId}&redemptionCode=${redemptionCode}`
    );
    return result;
  };
};

export const getTeamPurcheaseById = (id) => {
  return async function (dispatch) {
    console.log(id, "enter by id ");
    const result = await GetWithToken(`/website/team-purchase/${id}`);
    console.log(result, "result for all team");
    return result;
  };
};

export const updateOrderStatus = (id, orderType, status) => {
  let URL = "";
  let updatedStatus = {};
  switch (orderType) {
    case "physical":
      URL = `/order/status/${id}`;
      updatedStatus = { status };
      break;
    case "non-physical":
      URL = `/order/non-physical/status/${id}`;
      updatedStatus = { nonPhysicalStatus: status };
      break;
    case "cancelled":
      // URL = `/order/cancel-status/${id}`;
      // updatedStatus = { cancelOrderStatus: status };
      URL = `/order/status/${id}`;
      updatedStatus = { status };
      break;
    default:
      break;
  }

  return async function (dispatch) {
    // console.log(id, "enter");
    const result = await PatchWithToken(URL, updatedStatus);
    // console.log(result, "result for all order ");
    return result;
  };
};

export const updateOrderShippingStatusById = (id, data) => {
  return async function (dispatch) {
    const result = await PatchWithToken(`/order/shipping-status/${id}`, data);
    return result;
  };
};
export const updateOrderShippingStatusByIdByButton = (id, data) => {
  return async function (dispatch) {
    const result = await PatchWithToken(`/order/status/${id}`, data);
    return result;
  };
};

export const PrintAirwayBillByAwbNo = (billCode) => {
  return async function (dispatch) {
    const result = await GetWithToken(`/order/waybill/${billCode}`);
    // console.log(result, "print airway link response");
    return result;
  };
};

export const getAllSettlementData = (id, query) => {
  return async function (dispatch) {
    console.log(id, "enter");
    const result = await GetWithToken(
      `/order/settle/${id}?item=${query.item || ""}&startDate=${
        query.startDate || ""
      }&endDate=${query.endDate || ""}&order=${query.order || ""}&status=${
        query.status || ""
      }&offset=${query.offset || 0}`
    );
    console.log(result, "result for all consumer ");
    return result;
  };
};

export const getTrackingStatus = (data) => {
  return async function (dispatch) {
    const result = await PostWithToken(`/order/tracking`, data);
    return result;
  };
};

export const getLogisticDetail = (id) => {
  return async function (dispatch) {
    const result = await Get(`/order/detail/logistic/${id}`);
    return result;
  };
};
