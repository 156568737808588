import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { string } from "yup";
import Datepicker from "../../../components/datepicker/datepicker";
import InputField from "../../../components/formControl/input/InputField";
import RadioButtonsGroup from "../../../components/radio-button/RadioButtonsGroup";
import SelectInput from "../../../components/select";
// import LocalizationProvider from "@mui/lab";
import TimePicker from "@mui/lab/TimePicker";
import {
  ADD_AUTHORIED,
  ADD_BUSINESS_PARTNER,
  ADD_MERCHANT,
  ADD_SHIPPING_ADDRESS,
  ADD_SUB_ACCOUNT,
  EDIT_BANK_ACCOUNT_DIALOG,
  EDIT_MERCHANT_ACCOUNT_DIALOG,
  EDIT_PARTNERS_ACCOUNT_DIALOG,
  EDIT_SUB_ACCOUNT_DIALOG,
  EDIT_MERCHANT_SHIPPING_ADDRESS_DIALOG,
} from "../../../lib/constant";
import {
  open_dialog,
  showToast,
  startLoader,
  stopLoader,
  UploadImage,
} from "../../../lib/global";
import { getLocalStorage } from "../../../lib/session";
import { breadcrumbAction } from "../../../redux/actions/config.action";
import {
  addNewBusinessMerchant,
  addNewMerchant,
  generateMerchantIdAction,
  getMerchnatById,
  sendEmailToMerchantToLogin,
  updateMerchantByMercnahtId,
} from "../../../redux/actions/merchant-center.action";

import { statusData } from "../../../shared/language";
const merchantTypeOptions = [
  { label: "Individual", value: "Individual" },
  { label: "Business", value: "Business" },
];
const businessTypeOptions = [
  { label: "Sole Proprietor", value: "Sole Proprietor" },
  { label: "Government Entity", value: "Government Entity" },
  { label: "Partnership", value: "Partnership" },
  { label: "Corporation", value: "Corporation" },
  { label: "Non-Profit Org.", value: "Non-Profit Org." },
  { label: "Others", value: "Others" },
];

const initialMerchantDetail = {
  preferredMerchantName: "",
  businessNature: "",
  merchantImage: "",
  description: "",
  merchantName: "",
  passportNumber: "",
  designation: "",
  formReceivedDate: "",
  instagramAccountName: "",
  facebookAccountName: "",
  companyRegisteredName: "",
  companyRegisteredNumber: "",
  companyRegisteredDomain: "",
  registeredAddress: "",
  companyEmail: "",
  businessType: "",
  physicalCommission: String(0),
  nonPhysicalCommission: String(0),
  bankDetails: "",
};

const requiredFields = [
  "preferredMerchantName",
  "businessNature",
  "merchantName",
  "passportNumber",
  "designation",
  "formReceivedDate",
  "bankDetails",
  "authorizedPerson",
  "address",
];
const businessRequiredFields = [
  "preferredMerchantName",
  "businessNature",
  "merchantImage",
  "description",
  "merchantName",
  "passportNumber",
  "designation",
  "formReceivedDate",
  "bankDetails",
  "authorizedPerson",
  "address",
  "companyRegisteredName",
  "companyRegisteredNumber",
  "companyRegisteredDomain",
  "registeredAddress",
  "companyEmail",
  "businessType",
  "physicalCommission",
  "nonPhysicalCommission",
];
const initialBankInfo = [];
const initialAuthorizedPerson = [];
const initialShippingInfo = [];
const initialBusinessPartaners = [];
const initialSubAccount = [];

// const allStatus = ["New", "Active", "Blocked", "Closed"]

const AddMerchantAccount = () => {
  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [merchantType, setMerchantType] = useState("Business");
  const [merchantDetail, setMerchantDetail] = useState(initialMerchantDetail);
  const [bankInfo, setBankInfo] = useState(initialBankInfo);
  const [authorizedPerson, setAuthorizedPerson] = useState(
    initialAuthorizedPerson
  );

  const [employeeAccountDetail, setEmployeeAccoutnDEtail] = useState();
  const [shippingInfo, setShippingInfo] = useState(initialShippingInfo);
  const [businessPartners, setBusinessPartners] = useState(
    initialBusinessPartaners
  );
  const [poster, setPoster] = useState();
  const [file, setFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [employeeId, setEmployeeId] = useState();
  const [storeId, setStoreId] = useState(null);
  const [status, setStatus] = useState("");
  const [indexStatus, setIndexStatus] = useState(0);
  const [errorList, setErrorList] = useState([]);

  const generateUUIDV4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  const validateSaveToDraft = () => {
    let error = {};
    let formIsValid = true;

    if (!storeId) {
      formIsValid = false;
      error["storeId"] = "Merchant Id is required";
    }

    setErrorList(error);
    return formIsValid;
  };

  const validateIndividual = () => {
    let error = {};
    let formIsValid = true;
    if (!merchantDetail.preferredMerchantName) {
      formIsValid = false;
      error["preferredMerchantName"] = "preferred MerchantName is required";
    }
    if (!storeId) {
      formIsValid = false;
      error["storeId"] = "Merchant Id is required";
    }
    if (!merchantDetail.businessNature) {
      formIsValid = false;
      error["businessNature"] = "business Nature is required";
    }
    if (!merchantDetail.passportNumber) {
      formIsValid = false;
      error["passportNumber"] = "passport Number is required";
    }
    if (!merchantDetail.designation) {
      formIsValid = false;
      error["designation"] = "designation is required";
    }
    if (!merchantDetail.formReceivedDate) {
      formIsValid = false;
      error["formReceivedDate"] = "form Received Date is required";
    }
    if (!merchantDetail.physicalCommission) {
      formIsValid = false;
      error["physicalCommission"] = "physical Commission is required";
    }

    if (!merchantDetail.nonPhysicalCommission) {
      formIsValid = false;
      error["nonPhysicalCommission"] = "non-physical Commission is required";
    }

    if (!merchantDetail.merchantName) {
      formIsValid = false;
      error["merchantName"] = "merchantName is required";
    }
    if (merchantDetail.merchantName.length < 3) {
      formIsValid = false;
      error["merchantName"] = "merchantName should 3 or more charecter long";
    }
    if (merchantDetail.merchantName.length > 255) {
      formIsValid = false;
      error["merchantName"] = "merchantName should be max 255 characters";
    }

    if (bankInfo.length < 1) {
      formIsValid = false;
      error["bankAccount"] = "bank Account is required";
    }

    if (authorizedPerson.length < 1) {
      formIsValid = false;
      error["authorizedPerson"] = "author Person is required";
    }
    if (shippingInfo.length < 1) {
      formIsValid = false;
      error["shippingInfo"] = "shipping Info is required";
    }

    setErrorList(error);
    return formIsValid;
  };

  const validateBusiness = () => {
    let error = {};
    let formIsValid = true;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/;
    if (!storeId) {
      formIsValid = false;
      error["storeId"] = "Merchant Id is required";
    }
    if (!merchantDetail.preferredMerchantName) {
      formIsValid = false;
      error["preferredMerchantName"] = "preferred MerchantName is required";
    }

    if (!merchantDetail.businessNature) {
      formIsValid = false;
      error["businessNature"] = "business Nature is required";
    }
    if (!merchantDetail.passportNumber) {
      formIsValid = false;
      error["passportNumber"] = "passport Number is required";
    }
    if (!merchantDetail.designation) {
      formIsValid = false;
      error["designation"] = "designation is required";
    }
    if (!merchantDetail.formReceivedDate) {
      formIsValid = false;
      error["formReceivedDate"] = "form Received Date is required";
    }
    if (!merchantDetail.physicalCommission) {
      formIsValid = false;
      error["physicalCommission"] = "physical Commission is required";
    }

    if (!merchantDetail.nonPhysicalCommission) {
      formIsValid = false;
      error["nonPhysicalCommission"] = "non-physical Commission is required";
    }

    if (!merchantDetail.companyRegisteredName) {
      formIsValid = false;
      error["companyRegisteredName"] = "company Registered Name is required";
    }
    if (!merchantDetail.merchantName) {
      formIsValid = false;
      error["merchantName"] = "merchantName is required";
    }
    if (merchantDetail.merchantName.length < 3) {
      formIsValid = false;
      error["merchantName"] = "merchantName should 3 or more charecter long";
    }
    if (merchantDetail.merchantName.length > 255) {
      formIsValid = false;
      error["merchantName"] = "merchantName should be max 255 characters";
    }

    if (!merchantDetail.companyRegisteredNumber) {
      formIsValid = false;
      error["companyRegisteredNumber"] =
        "company Registered Number is required";
    }
    if (!merchantDetail.registeredAddress) {
      formIsValid = false;
      error["registeredAddress"] = "registered Address is required";
    }
    if (!merchantDetail.companyEmail) {
      formIsValid = false;
      error["companyEmail"] = "company Email is required";
    }
    if (
      merchantDetail.companyEmail &&
      emailRegex.test(merchantDetail.companyEmail) === false
    ) {
      formIsValid = false;
      error["companyEmail"] = " valid email required";
    }
    if (!merchantDetail.businessType) {
      formIsValid = false;
      error["businessType"] = "business Type is required";
    }

    if (bankInfo.length < 1) {
      formIsValid = false;
      error["bankAccount"] = "bank Account is required";
    }

    if (authorizedPerson.length < 1) {
      formIsValid = false;
      error["authorizedPerson"] = "author Person is required";
    }
    if (shippingInfo.length < 1) {
      formIsValid = false;
      error["shippingInfo"] = "shipping Info is required";
    }
    if (businessPartners.length < 1) {
      formIsValid = false;
      error["businessPartners"] = "business Partners is required";
    }

    setErrorList(error);
    return formIsValid;
  };

  const handleKeyPress = (e) => {
    const key = e.key;
    const regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  useEffect(() => {
    if (id) {
      const response = dispatch(getMerchnatById(id));
      response
        .then((data) => {
          const merchantDetail = {
            preferredMerchantName: data.data.data?.preferredMerchantName,
            businessNature: data.data.data?.businessNature,
            merchantImage: data.data.data?.merchantImage,
            description: data.data.data?.description,
            merchantName: data.data.data?.merchantName,
            passportNumber: data.data.data?.passportNumber,
            designation: data.data.data?.designation,
            formReceivedDate: data.data.data?.formReceivedDate,
            instagramAccountName: data.data.data?.instagramAccountName,
            facebookAccountName: data.data.data?.facebookAccountName,
            companyRegisteredName: data.data.data?.companyRegisteredName,
            companyRegisteredNumber: data.data.data?.companyRegisteredNumber,
            companyRegisteredDomain: data.data.data?.companyRegisteredDomain,
            registeredAddress: data.data.data?.registeredAddress,
            companyEmail: data.data.data?.companyEmail,
            businessType: data.data.data?.businessType,
            status: data.data.data?.status,
            storeId: data.data.data?.storeId,
            physicalCommission: data.data.data?.physicalCommission,
            nonPhysicalCommission: data.data.data?.nonPhysicalCommission,
            flexOption: data.data.data?.flexOption,
            flexToppingPercentage: data.data.data?.flexToppingPercentage,
            flexRebateTransactionRate: data.data.data?.flexRebateTransactionRate,
            flexRebateRate: data.data.data?.flexRebateRate,
            flexToppingTransactionRate: data.data.data?.flexToppingTransactionRate,

            // poster: data.data.data?.merchantImage
          };
          setPoster(data.data.data?.merchantImage);
          setMerchantDetail(merchantDetail);
          setAuthorizedPerson(data.data.data?.authorizedPerson);
          setShippingInfo(data.data.data?.address);
          setBankInfo(data.data.data?.bankDetails);
          setMerchantType(data.data.data?.merchantType);
          setStatus({
            value: data.data.data?.status,
            label: data.data.data?.status,
          });
          setStoreId(data.data.data?.storeId);
          setBusinessPartners(data.data.data?.partners);
          // setIndexStatus(allStatus.indexOf(data.data.data?.status))
          // address()
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  useEffect(() => {
    const breadcrumb = [
      { label: "Merchant Account", url: "/merchant-center/merchant-account" },
      { label: id ? "Edit Merchant" : "Add Merchant", url: null },
    ];
    dispatch(breadcrumbAction(breadcrumb));
  }, []);

  const handleSelectFile = (e) => {
    const fileData = e.target.files[0];

    setFile(fileData);
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      setPoster(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };
  const onChangeMerchantDetail = (e, title, val) => {
    const key = title || e.target.id;
    const value = val || e.target.value;
    setMerchantDetail((pre) => ({ ...pre, [key]: value }));
  };

  const handleAddAccount = () => {
    open_dialog(ADD_MERCHANT, {
      onSubmit: onSubmitBankAccountModal,
    });
  };
  const onSubmitBankAccountModal = (data) => {
    console.log("Data:", data);
    // setBankInfo((pre) => [...pre, data]);
    setBankInfo((pre) => {
      if (!Array.isArray(pre)) {
        // If pre is not an array, initialize it as an empty array
        pre = [];
      }
      return [...pre, data];
    });
  };

  const handleAddAuthoried = () => {
    open_dialog(ADD_AUTHORIED, {
      onSubmit: onSubmitAuthorizedModal,
      authorizedPerson,
    });
  };

  const onSubmitAuthorizedModal = (data) => {
    const data1 = {
      ...data,
      storeId,
    };
    setAuthorizedPerson((pre) => [...pre, data1]);
    // setEmployeeId(data.employeeId);
    // setEmployeeAccoutnDEtail(data);
  };

  const handleAddShippingAddress = () => {
    open_dialog(ADD_SHIPPING_ADDRESS, {
      onSubmit: onSubmitShippingAddressModal,
    });
  };
  const onSubmitShippingAddressModal = (data) => {
    // setShippingInfo((pre) => [...pre, data]);
    setShippingInfo((pre) => {
      if (!Array.isArray(pre)) {
        // If pre is not an array, initialize it as an empty array
        pre = [];
      }
      return [...pre, data];
    });
  };

  const handleAddPartner = () => {
    open_dialog(ADD_BUSINESS_PARTNER, {
      onSubmit: onSubmitPartnerModal,
    });
  };
  const onSubmitPartnerModal = (data) => {
    // setBusinessPartners((pre) => [...pre, data]);
    setBusinessPartners((pre) => {
      if (!Array.isArray(pre)) {
        // If pre is not an array, initialize it as an empty array
        pre = [];
      }
      return [...pre, data];
    });
  };

  const handleSubAddAuthoried = () => {
    open_dialog(ADD_SUB_ACCOUNT, {
      onSubmit: onSubmitSubAccountModal,
    });
  };

  const onSubmitSubAccountModal = (data) => {
    const data1 = {
      ...data,
      employeeId: employeeId,
    };
    setAuthorizedPerson((pre) => [...pre, data1]);
  };

  const onSaveAndDeactivate = () => {
    onSubmitAddMerchant("New", "saveToDraft");
  };

  const onSubmitAddMerchant = async (userStatus, saveStatus) => {
    let resultImage = await UploadImage(file, `gogo/merchant`);
    let uploadImage = null;
    if (!resultImage.error) {
      uploadImage = resultImage;
    }

    if (merchantType == "Individual") {
      const validateBeforeSave = saveStatus
        ? validateSaveToDraft()
        : validateIndividual();

      if (validateBeforeSave) {
        let imageUrl = !file ? merchantDetail?.merchantImage : uploadImage;

        const payload = {
          ...merchantDetail,
          ...employeeAccountDetail,
          merchantImage: imageUrl,
          bankDetails: bankInfo,
          authorizedPerson,
          address: shippingInfo,
          createdBy: getLocalStorage("userName"),
          partners: businessPartners,
          status: userStatus == "New" ? "New" : status.value,
          storeId,
          merchantType,
        };

        if (!id) {
          const response = dispatch(addNewMerchant(payload, saveStatus));
          response
            .then((data) => {
              if (data && data.data.status === 200) {
                const payloadData = {
                  authorizedPerson,
                  storeId,
                  preferredMerchantName: merchantDetail.preferredMerchantName,
                };

                showToast(data.data.message, "success");
                stopLoader();
                history.push("/merchant-center/merchant-account");
                // dispatch(sendEmailToMerchantToLogin(payloadData))
                //   .then((data) => {
                //     stopLoader();
                //   })
                //   .catch((err) => {
                //     stopLoader();
                //   });
              } else {
                setTimeout(() => {
                  stopLoader();
                  showToast(data.data.message, "error");
                }, 1000);
              }
            })
            .catch((err) => {
              const { response } = err;
              setTimeout(() => {
                stopLoader();
                showToast(response.data.message, "error");
              }, 1000);
            });
        } else {
          const payloadData = {
            authorizedPerson,
            storeId: merchantDetail.storeId,
            preferredMerchantName: merchantDetail.preferredMerchantName,
          };

          const response = dispatch(updateMerchantByMercnahtId(id, payload));
          response
            .then((data) => {
              setTimeout(() => {
                showToast(data.data.message, "success");
                stopLoader();
                history.push("/merchant-center/merchant-account");
              }, 1000);
            })
            .catch((err) => {
              console.log({ err });
              stopLoader();
            });
        }
      }
    } else {
      const validateBeforeSave = saveStatus
        ? validateSaveToDraft()
        : validateBusiness();

      if (validateBeforeSave) {
        let imageUrl = !file ? merchantDetail?.merchantImage : uploadImage;
        const payload = {
          ...merchantDetail,
          ...employeeAccountDetail,
          merchantImage: imageUrl,
          bankDetails: bankInfo,
          authorizedPerson,
          createdBy: getLocalStorage("userName"),
          address: shippingInfo,
          partners: businessPartners,
          status: userStatus == "New" ? "New" : status.value,
          storeId,
          merchantType,
        };

        // let invalidFieldName = [];
        // for (let item of requiredFields) {
        //   if (!payload[item]) {
        //     invalidFieldName.push(item);
        //   }
        // }
        // if (invalidFieldName?.length) {
        //   const label = invalidFieldName.join(", ");
        //   return setError(`${label} fields are invalid or missing!`);
        // }

        if (!id) {
          const response = dispatch(addNewMerchant(payload, saveStatus));
          response
            .then((data) => {
              if (data && data.data.status === 200) {
                const payloadData = {
                  authorizedPerson,
                  storeId: merchantDetail.storeId,
                  preferredMerchantName: merchantDetail.preferredMerchantName,
                };

                showToast(data.data.message, "success");
                stopLoader();
                history.push("/merchant-center/merchant-account");
                // dispatch(sendEmailToMerchantToLogin(payloadData));
              } else {
                setTimeout(() => {
                  stopLoader();
                  showToast(data.data.message, "error");
                }, 1000);
              }
            })
            .catch((err) => {
              const { response } = err;
              setTimeout(() => {
                stopLoader();
                showToast(response.data.message, "error");
              }, 1000);
            });
        } else {
          const payloadData = {
            authorizedPerson,
            storeId: merchantDetail.storeId,
            preferredMerchantName: merchantDetail.preferredMerchantName,
          };
          // dispatch(sendEmailToMerchantToLogin(payloadData));

          const response = dispatch(updateMerchantByMercnahtId(id, payload));
          response
            .then((data) => {
              setTimeout(() => {
                showToast(data.data.message, "success");
                stopLoader();
                history.push("/merchant-center/merchant-account");
              }, 1000);
            })
            .catch((err) => {
              console.log({ err });
              stopLoader();
            });
        }
      }
    }
  };

  const back = () => history.goBack();

  const onSubmitEditBankAccount = (data) => {
    const items = [...bankInfo];
    items[data.index] = data;
    setBankInfo(items);
  };

  const editBankAccount = (bankName, bankAccountNo, beneciaryName, index) => {
    open_dialog(EDIT_BANK_ACCOUNT_DIALOG, {
      onSubmit: onSubmitEditBankAccount,
      bankName,
      bankAccountNo,
      beneciaryName,
      index,
    });
  };

  const onSubmitEditShippingAddress = (data) => {
    const items = [...shippingInfo];
    items[data.index] = data;
    setShippingInfo(items);
  };

  const editShippingAddress = (
    fullName,
    phoneNumber,
    addressLine1,
    addressLine2,
    postalCode,
    state,
    setAsPickupAddress,
    setAsReturnAddress,
    index
  ) => {
    open_dialog(EDIT_MERCHANT_SHIPPING_ADDRESS_DIALOG, {
      onSubmit: onSubmitEditShippingAddress,
      fullName,
      phoneNumber,
      addressLine1,
      addressLine2,
      postalCode,
      state,
      setAsPickupAddress,
      setAsReturnAddress,
      index,
    });
  };

  const deleteBankAccount = (index1) => {
    setBankInfo(bankInfo.filter((item, index) => index !== index1));
  };

  const deleteShippingAddress = (index1) => {
    setShippingInfo(shippingInfo.filter((item, index) => index !== index1));
  };

  const onSubmitEditMerchantAccount = (data) => {
    setEmployeeAccoutnDEtail(data);
  };
  const editMerchantAccount = (
    employeeName,
    passportNumber,
    email,
    phoneNumber,
    loginID
  ) => {
    open_dialog(EDIT_MERCHANT_ACCOUNT_DIALOG, {
      onSubmit: onSubmitEditMerchantAccount,
      employeeName,
      passportNumber,
      email,
      phoneNumber,
      loginID,
    });
  };

  // const deleteMerchantAccount = ()=>{
  //    setAuthorizedPerson(authorizedPerson.filter((item, index) => index !== index1))
  // }
  const onSubmitEditSubAccount = (data) => {
    let index = data.index
    delete data.index
    if (data.storeId == null) {
      data.storeId = storeId
    }
    if (data.userId == null) {
      data.userId = generateUUIDV4()
    }

    const items = [...authorizedPerson];
    items[index] = data;
    setAuthorizedPerson(items);
  };

  const editsubAccount = (
    employeeName,
    passportNumber,
    email,
    phone,
    password,
    index,
    employeeId,
    userId,
    storeId
  ) => {
    open_dialog(EDIT_SUB_ACCOUNT_DIALOG, {
      onSubmit: onSubmitEditSubAccount,
      employeeName,
      passportNumber,
      email,
      phone,
      password,
      index,
      employeeId,
      userId,
      storeId
    });
  };

  const deleteSubAccount = (index1) => {
    setAuthorizedPerson(
      authorizedPerson.filter((item, index) => index !== index1)
    );
  };

  const onSubmitEditParterAccount = (data) => {
    const items = [...businessPartners];
    items[data.index] = data;
    setBusinessPartners(items);
  };

  const editPartnerAccount = (partnerName, passportNumber, phone, index) => {
    open_dialog(EDIT_PARTNERS_ACCOUNT_DIALOG, {
      onSubmit: onSubmitEditParterAccount,
      partnerName,
      passportNumber,
      phone,
      index,
    });
  };

  const deletePartnerAccount = (index1) => {
    setBusinessPartners(
      businessPartners.filter((item, index) => index !== index1)
    );
  };

  const generateMerchantIdFun = () => {
    const response = dispatch(generateMerchantIdAction());
    response.then((data) => {
      setStoreId(data && data.data.storeId);
    });
  };

  return (
    <React.Fragment>
      <div className="container_body container-fluid">
        <div className="card p-3">
          <h4 className="">SELECT YOUR TYPE</h4>
          <div className="ml-5 d-flex">
            <label className="mt-2 mx-3">Merchant Type</label>
            <RadioButtonsGroup
              options={merchantTypeOptions}
              name="merchant-type"
              value={merchantType}
              onChange={(e) => setMerchantType(e.target.value)}
            />
          </div>
        </div>
        <br />
        <div className="card p-3">
          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small"
            >
              Merchant Id
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="merchantId"
                onChange={onChangeMerchantDetail}
                value={
                  merchantDetail.storeId ? merchantDetail.storeId : storeId
                }
                placeholder="Merchant Id"
                formClass="col-6 m-0 p-0"
                className={""}
                disabled={true}
                formSubmitted={formSubmitted}
              />
              <button
                onClick={() => generateMerchantIdFun()}
                className="btn btn-dark mx-2 h-fit-content m-auto"
                visible={String(!id ? true : false)}
              >
                Generate
              </button>
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.storeId}
            </p>
          </div>
          <div className="col-12 py-3 row" visible={id ? "true" : "false"}>
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small"
            >
              Status
            </label>
            <div className="col-4">
              <SelectInput
                options={statusData}
                placeholder="Select status"
                value={status}
                onChange={setStatus}
              />
            </div>
          </div>

          <div
            visible={String(merchantType === "Business")}
            className="col-12 row"
          >
            <label
              htmlFor="businessType"
              className="col-3 my-auto text-right small imp"
            >
              Type of business
            </label>
            <div className="col-9 d-flex">
              <RadioButtonsGroup
                options={businessTypeOptions}
                name="business-type"
                size="tiny"
                labelClass="my-0"
                value={merchantDetail.businessType}
                onChange={(e) => onChangeMerchantDetail(e, "businessType")}
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.businessType}
            </p>
          </div>

          <div
            visible={String(merchantType === "Business")}
            className="col-12 row"
          >
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Company Registered Name
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="companyRegisteredName"
                value={merchantDetail.companyRegisteredName}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.companyRegisteredName}
            </p>
          </div>

          <div
            visible={String(merchantType === "Business")}
            className="col-12 row"
          >
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Company Registered No.
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="companyRegisteredNumber"
                value={merchantDetail.companyRegisteredNumber}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.companyRegisteredNumber}
            </p>
          </div>

          <div
            visible={String(merchantType === "Business")}
            className="col-12 row"
          >
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small "
            >
              Company Domain Name
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="companyRegisteredDomain"
                value={merchantDetail.companyRegisteredDomain}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input(Optional)"
              />
            </div>
          </div>

          <div
            visible={String(merchantType === "Business")}
            className="col-12 row"
          >
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Registered Address
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="registeredAddress"
                value={merchantDetail.registeredAddress}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.registeredAddress}
            </p>
          </div>

          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Preferred Merchant Name
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="preferredMerchantName"
                value={merchantDetail.preferredMerchantName}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
                maxLength={255}
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.preferredMerchantName}
            </p>
          </div>

          <div
            visible={String(merchantType === "Business")}
            className="col-12 row"
          >
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Company Email
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="companyEmail"
                value={merchantDetail.companyEmail}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.companyEmail}
            </p>
          </div>

          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Business Nature
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="businessNature"
                value={merchantDetail.businessNature}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.businessNature}
            </p>
          </div>

          <div className="col-12 row my-2">
            <label htmlFor="merchantId" className="col-3 text-right ">
              Image
            </label>
            <div className="col-9">
              <div className="col-12 row">
                <input
                  type="file"
                  name="playlist_poster"
                  id="playlist-poster"
                  onChange={handleSelectFile}
                  className="form-input col-md-8 p-0"
                />
                {/* <input
                    accept="image/*"
                    id="product-image-2"
                    className="d-none"
                    multiple
                    type="file" />
                  <label htmlFor="product-image-2">
                    <Image
                      src="/icons/upload_icon.svg"
                      alt="upload"
                      className={"pointer"}
                    />
                  </label> */}
              </div>

              <div className="col-12 px-0 py-2" style={{ height: "120px" }}>
                {poster ? (
                  <img
                    src={poster}
                    height="100px"
                    width="120px"
                    className="object-fit-cover"
                    alt="playlist poster"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small"
            >
              Description
            </label>
            <div className="col-9 d-flex">
              <textarea
                type="text"
                id="description"
                value={merchantDetail.description}
                onChange={onChangeMerchantDetail}
                className={"form-control w-100"}
                placeholder="input(Optional)"
                rows={5}
              ></textarea>
            </div>
          </div>

          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Name
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="merchantName"
                value={merchantDetail.merchantName}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
                maxLength={255}
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.merchantName}
            </p>
          </div>

          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              NRIC / Passport No.
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="passportNumber"
                value={merchantDetail.passportNumber}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.passportNumber}
            </p>
          </div>

          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Designation
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="designation"
                value={merchantDetail.designation}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.designation}
            </p>
          </div>

          <div className="col-12 row my-2">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Form Received Date
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="date"
                id="formReceivedDate"
                value={merchantDetail.formReceivedDate}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="dd/mm/yyyy"
              />

              {/*<LocalizationProvider dateAdapter={AdapterDateFns}>*/}
              {/*  <DatePicker*/}
              {/*    label="Basic example"*/}
              {/*    value={merchantDetail.formReceivedDate}*/}
              {/*    // onChange={(newValue) => {*/}
              {/*    //   setValue(newValue);*/}
              {/*    // }}*/}
              {/*    renderInput={(params) => <TextField {...params} />}*/}
              {/*  />*/}
              {/*</LocalizationProvider>*/}
              {/*<div>*/}
              {/*<Datepicker*/}
              {/*  // format="DD/MM/YYYY"*/}
              {/*  color="#fff"*/}
              {/*  value={merchantDetail.formReceivedDate}*/}
              {/*  label="MM/DD/YYYY"*/}
              {/*  onChange={(e) => {*/}
              {/*    onChangeMerchantDetail(e, "formReceivedDate", e);*/}
              {/*  }}*/}
              {/*/>*/}

              {/*  <TimePicker*/}
              {/*    label="Basic example"*/}
              {/*    value={""}*/}
              {/*    // onChange={(newValue) => {*/}
              {/*    //   setValue(newValue);*/}
              {/*    // }}*/}
              {/*    renderInput={(params) => <TextField {...params} />}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.formReceivedDate}
            </p>
          </div>

          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small"
            >
              Instagram Account
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="instagramAccountName"
                value={merchantDetail.instagramAccountName}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input(Optional)"
              />
            </div>
          </div>

          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small"
            >
              Facebook Account
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                id="facebookAccountName"
                value={merchantDetail.facebookAccountName}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input(Optional)"
              />
            </div>
          </div>
          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Physical Commission Fee
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="number"
                min="0"
                max="100"
                id="physicalCommission"
                value={merchantDetail.physicalCommission}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.physicalCommission}
            </p>
          </div>
          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Non-Physical Commission Fee
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="number"
                min="0"
                max="100"
                id="nonPhysicalCommission"
                value={merchantDetail.nonPhysicalCommission}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={onChangeMerchantDetail}
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="input"
              />
            </div>
            <p
              style={{ marginLeft: "27%", fontSize: "12px" }}
              className="text-danger"
            >
              {errorList.nonPhysicalCommission}
            </p>
          </div>
          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Flex Option
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="text"
                min="0"
                max="100"
                id="flexOption"
                value={merchantDetail.flexOption}
                disabled
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="Sync From Flex"
              />
            </div>
          </div>
          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Flex Topping Percentage
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="number"
                min="0"
                max="100"
                id="flexToppingPercentage"
                value={merchantDetail.flexToppingPercentage}
                disabled
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="Sync From Flex"
              />
            </div>
          </div>
          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Flex Rebate Transaction Rate
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="number"
                min="0"
                max="100"
                id="flexRebateTransactionRate"
                value={merchantDetail.flexRebateTransactionRate}
                disabled
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="Sync From Flex"
              />
            </div>
          </div>
          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Flex Rebate Rate
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="number"
                min="0"
                max="100"
                id="flexRebateRate"
                value={merchantDetail.flexRebateRate}
                disabled
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="Sync From Flex"
              />
            </div>
          </div>
          <div className="col-12 row">
            <label
              htmlFor="merchantId"
              className="col-3 my-auto text-right small imp"
            >
              Flex Topping Transaction Rate
            </label>
            <div className="col-9 d-flex">
              <InputField
                type="number"
                min="0"
                max="100"
                id="flexToppingTransactionRate"
                value={merchantDetail.flexToppingTransactionRate}
                disabled
                className={"w-100"}
                formSubmitted={formSubmitted}
                placeholder="Sync From Flex"
              />
            </div>
          </div>
        </div>
        <br />
        <div
          visible={String(merchantType === "Business")}
          className="card p-3 "
        >
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 htmlFor="merchantId" className="m-0 my-auto imp">
                <label htmlFor="merchantId" className="m-0 my-auto imp">
                  NAME OF DIRECTORS/PARTNERS
                </label>
              </h6>
              <label className="text-muted  font-size-account">
                Manage merchant’s company director/partners
              </label>
              <p
                style={{ marginLeft: "0%", fontSize: "12px" }}
                className="text-danger"
              >
                {errorList.businessPartners}
              </p>
            </div>
            <div className="">
              <button onClick={handleAddPartner} className="btn btn-dark px-2">
                Add Director / Partners
              </button>
            </div>
          </div>
          {businessPartners && businessPartners.length > 0 ? (
            <TableContainer>
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead style={{ background: "#F5F8FA" }}>
                  <TableRow>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      Director / Partners Name
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      NRIC / Passport No.
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      Phone Number
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...businessPartners].map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" className="td_cell">
                        {row.partnerName || "N/A"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.passportNumber || "N/A"}
                      </TableCell>

                      <TableCell align="center" className="td_cell">
                        {row.phone || "N/A"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        <div className="col-2 d-flex">
                          <div
                            className="text-info"
                            onClick={() =>
                              editPartnerAccount(
                                row.partnerName,
                                row.passportNumber,
                                row.phone,
                                index
                              )
                            }
                          >
                            Edit
                          </div>
                          <div className="text-info mx-2">|</div>
                          <div
                            className="text-info"
                            onClick={() => deletePartnerAccount(index)}
                          >
                            Delete
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </div>
        <br />
        <div className="card p-3 ">
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 htmlFor="merchantId" className="m-0 my-auto imp">
                <label htmlFor="merchantId" className="m-0 my-auto imp">
                  BANK ACCOUNT INFORMATION
                </label>
              </h6>
              <label className="text-muted  font-size-account">
                Manage merchant's bank account
              </label>
              <p
                style={{ marginLeft: "0%", fontSize: "12px" }}
                className="text-danger"
              >
                {errorList.bankAccount}
              </p>
            </div>
            <div className="">
              <button onClick={handleAddAccount} className="btn btn-dark px-2">
                + Add Bank Account
              </button>
            </div>
          </div>

          {bankInfo?.map((item, index) => (
            <div key={index} className="col-12 row">
              <hr className="w-100" />
              <div className="col-10">
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Bank Name
                  </label>
                  <label className="col-9 my-auto small">{item.bankName}</label>
                </div>
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Account No.
                  </label>
                  <label className="col-9 my-auto small">
                    {item.bankAccountNo}
                  </label>
                </div>
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Beneficiary Name
                  </label>
                  <label className="col-9 my-auto small">
                    {item.beneciaryName}
                  </label>
                </div>
              </div>
              <div className="col-2 d-flex">
                <div
                  className="text-info"
                  onClick={() =>
                    editBankAccount(
                      item.bankName,
                      item.bankAccountNo,
                      item.beneciaryName,
                      index
                    )
                  }
                >
                  Edit
                </div>
                <div className="text-info mx-2">|</div>
                <div
                  className="text-info"
                  onClick={() => deleteBankAccount(index)}
                >
                  Delete
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />

        <div className="card p-3">
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 htmlFor="merchantId" className="m-0 my-auto imp">
                <label htmlFor="merchantId" className="m-0 my-auto imp">
                  NAME OF AUTHORIZED
                </label>
              </h6>
              <label className="text-muted  font-size-account">
                Manage merchant's login credential
              </label>
              <p
                style={{ marginLeft: "0%", fontSize: "12px" }}
                className="text-danger"
              >
                {errorList.authorizedPerson}
              </p>
            </div>
            <div className="">
              <button
                onClick={handleAddAuthoried}
                className="btn btn-dark px-2"
              >
                + Add Authorized Person
              </button>
            </div>
          </div>

          {authorizedPerson && authorizedPerson.length > 0 ? (
            <TableContainer>
              {/* <p style={{ textAlign: "center" }} className="text-align-center">
                Sub Account
              </p> */}
              <Table
                sx={{ minWidth: 650 }}
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      Employee Name
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      LoginId.
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      password.
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      NRIC / Passport No.
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      Email Address
                    </TableCell>
                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      Phone Number
                    </TableCell>

                    <TableCell
                      className="th_cell"
                      component="th"
                      align="center"
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...authorizedPerson].map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" className="td_cell">
                        {row.username || "N/A"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.loginId || "N/A"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {"*************"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.passportNumber || "N/A"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row?.email || "N/A"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        {row.phone || "N/A"}
                      </TableCell>
                      <TableCell align="center" className="td_cell">
                        <div className="col-2 d-flex">
                          <div
                            className="text-info"
                            onClick={() =>
                              editsubAccount(
                                row.username,
                                row.passportNumber,
                                row.email,
                                row.phone,
                                row.password,
                                index,
                                row.loginId
                              )
                            }
                          >
                            Edit
                          </div>
                          <div className="text-info mx-2">|</div>
                          <div
                            className="text-info"
                            onClick={() => deleteSubAccount(index)}
                          >
                            Delete
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
          {/* ))} */}
        </div>

        <br />
        <div className="card p-3">
          <div className="d-flex justify-content-between">
            <div className="">
              <h6 htmlFor="merchantId" className="m-0 my-auto imp">
                <label htmlFor="merchantId" className="m-0 my-auto imp">
                  SHIPPING INFORMATION
                </label>
              </h6>
              <label className="text-muted  font-size-account">
                Manage pickup and return address
              </label>
              <p
                style={{ marginLeft: "0%", fontSize: "12px" }}
                className="text-danger"
              >
                {errorList.shippingInfo}
              </p>
            </div>
            <div className="">
              <button
                onClick={handleAddShippingAddress}
                className="btn btn-dark px-2"
              >
                + Add New Address
              </button>
            </div>
          </div>
          {shippingInfo?.map((item, index) => (
            <div key={index} className="col-12 row">
              <hr className="w-100" />
              <div className="col-10">
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Full Name
                  </label>
                  <label className="col-9 my-auto small">
                    {item.fullName}
                    {item.setAsPickupAddress && (
                      <span className="px-3 text-danger">Pickup Address</span>
                    )}
                    {item.setAsReturnAddress && (
                      <span className="px-3 text-success">Return Address</span>
                    )}
                  </label>
                </div>
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Phone Number
                  </label>
                  <label className="col-9 my-auto small">
                    {item.phoneNumber}
                  </label>
                </div>
                <div>
                  <label className="col-3 my-auto small text-right text-muted">
                    Address
                  </label>
                  <label className="col-9 my-auto small">{item.address}</label>
                </div>
              </div>
              <div className="col-2 d-flex">
                <div
                  className="text-info"
                  onClick={() =>
                    editShippingAddress(
                      item.fullName,
                      item.phoneNumber,
                      item.addressLine1,
                      item.addressLine2,
                      item.postalCode,
                      item.state,
                      item.setAsPickupAddress,
                      item.setAsReturnAddress,
                      index
                    )
                  }
                >
                  Edit
                </div>
                <div className="text-info mx-2">|</div>
                <div
                  className="text-info"
                  onClick={() => deleteShippingAddress(index)}
                >
                  Delete
                </div>
              </div>
            </div>
          ))}
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-end">
          {error && <label className="text-danger">{error}</label>}
        </div>
        <footer className="card p-3">
          <div className="d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-dark mx-2"
              onClick={back}
            >
              Cancel
            </button>
            <button
              visible={String(!id ? true : false)}
              onClick={onSaveAndDeactivate}
              type="button"
              className="btn text-nowrap btn-outline-dark mx-2"
            >
              Save as Draft
            </button>
            <button
              type="button"
              className="btn btn-dark"
              onClick={() => onSubmitAddMerchant()}
            >
              {id ? "Update" : "Create & Approve"}
            </button>
          </div>
        </footer>
        <br />
      </div>
    </React.Fragment>
  );
};

export default AddMerchantAccount;
